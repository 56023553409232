export enum TX_KEY {
  CREATE_DAO = 'TX:CREATE_DAO',
  STAKE_TOKEN = 'TX:STAKE_TOKEN',
  UNSTAKE_TOKEN = 'TX:UNSTAKE_TOKEN',
  STAKE_NFT = 'TX:STAKE_NFT',
  UNSTAKE_NFT = 'TX:UNSTAKE_NFT',
  CLAIM = 'TX:CLAIM',
  CREATE_PROPOSAL = 'TX:CREATE_PROPOSAL',
  EXECUTE_PROPOSAL = 'TX:EXECUTE_PROPOSAL',
  CAST_VOTE = 'TX:CAST_VOTE',
  DEPOSIT = 'TX:DEPOSIT',
  CLAIM_REWARDS = 'TX:CLAIM_REWARDS',
  DEPOSIT_INTO_FUNDS_DISTRIBUTOR = 'TX:DEPOSIT_INTO_FUNDS_DISTRIBUTOR',
}
