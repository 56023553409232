export const TerraStationIcon = () => (
  <svg width="1em" height="0.84em" viewBox="0 0 26 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_455_10100)">
      <path
        d="M2.60102 5.20201C3.44013 3.6118 5.19657 2.82458 6.55894 3.43317L6.62899 3.46593L9.71983 4.99636C10.4036 4.8072 11.1344 4.85543 11.7852 5.13267L11.882 5.17571L13.9904 6.20014L20.2077 9.2438C21.3186 9.79164 21.9102 10.9655 21.9015 12.2753L21.9 12.3523L23.0109 12.869L21.0941 16.5232L19.9713 15.9739C18.9136 16.7893 17.5822 17.0464 16.4614 16.537L16.3775 16.4973L8.08766 12.4467C8.08196 12.4491 8.07581 12.4504 8.06959 12.4504C8.06336 12.4504 8.05721 12.4491 8.05152 12.4467C7.4178 12.1362 6.92106 11.6156 6.65226 10.9801L6.61711 10.8928L3.57751 9.39134C3.56751 9.37982 3.55427 9.37135 3.53939 9.36695L3.52949 9.36527C2.15771 8.6969 1.74731 6.81925 2.60127 5.20201H2.60102Z"
        fill="#2B8FF3"
      />
      <path
        d="M4.10139 15.6523L9.39916 18.3863L6.02688 21.7164C6.00549 21.7419 5.97608 21.7599 5.94311 21.7678C5.91013 21.7756 5.87541 21.7729 5.84421 21.7599H5.82936L0.765003 19.257C0.742473 19.2453 0.722838 19.229 0.707451 19.2093C0.692064 19.1895 0.681292 19.1668 0.675879 19.1427C0.670467 19.1185 0.670542 19.0935 0.6761 19.0694C0.681658 19.0453 0.692566 19.0226 0.708072 19.003L4.10139 15.6521V15.6523ZM8.5452 11.3767L8.56971 11.3845L13.6425 13.8873C13.6804 13.9043 13.7101 13.9346 13.7258 13.972C13.7414 14.0095 13.7417 14.0512 13.7266 14.0889L13.7143 14.1128C13.7095 14.1246 13.7024 14.1355 13.6935 14.1448V14.1623L9.7702 18.0391L4.45733 15.3022L8.38678 11.4278C8.40542 11.4056 8.43021 11.389 8.45826 11.3799C8.48631 11.3708 8.51647 11.3696 8.5452 11.3764V11.3767ZM23.0378 12.9095C23.7145 13.2392 23.8192 14.3213 23.2893 15.334C22.7591 16.3465 21.7917 16.8991 21.115 16.5694C20.438 16.2394 20.3333 15.1575 20.8633 14.1446C21.3935 13.135 22.3608 12.5795 23.0375 12.9095H23.0378ZM8.80584 4.80248C9.11401 4.79926 9.41966 4.85636 9.70436 4.97035C9.45683 5.03731 9.21723 5.12842 8.98852 5.24224L8.91079 5.27979H8.7999C7.82367 5.27979 6.81129 5.9857 6.26921 6.99842C5.68827 8.10942 5.7603 9.33903 6.39198 10.0681C6.43748 10.3447 6.51179 10.6162 6.61376 10.8783C5.37094 10.1492 5.02639 8.35835 5.85609 6.7844C6.4848 5.55479 7.67094 4.80248 8.80584 4.80248ZM16.1462 4.06166L21.4474 6.79587L19.3838 8.83853L13.9932 6.19996L16.1467 4.06166H16.1462ZM20.1979 0.156657L20.2227 0.164548L25.3227 2.66729C25.3646 2.68666 25.3971 2.72101 25.4132 2.7631C25.4294 2.80518 25.428 2.85169 25.4093 2.89279C25.4047 2.90302 25.3987 2.91259 25.3915 2.92124L21.8157 6.43718L16.5058 3.70583L20.0398 0.207831C20.0557 0.188906 20.0762 0.174013 20.0994 0.164432C20.1227 0.154851 20.148 0.15087 20.1732 0.152831L20.1979 0.156657Z"
        fill="#A3CFFA"
      />
    </g>
    <defs>
      <clipPath id="clip0_455_10100">
        <rect width="25" height="22" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
